import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useLocales } from "hooks/useFeature";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import {
  FlagUK,
  FlagGermany,
  FlagNetherlands,
  FlagFrance,
  FlagRussia,
  FlagTurkey,
  FlagSweden,
  FlagUkraine,
  FlagArabic,
  FlagPoland,
  FlagSpain,
  FlagItaly,
} from "../../../public/assets/index";
import { setLanguage } from "store/actions/layout";
import { forceRefresh } from "store/actions/common";
import { useDispatch } from "react-redux";

interface LanguageSelectProps extends SelectProps {
  onLanguageChange?: () => void;
  redirectRouterArgs?: any;
  availableLanguages?: string[];
}

export const languageOptions = () => [
  {
    label: "english",
    value: "en",
    icon: <img src={FlagUK.src} alt={"EN"} />,
  },
  {
    label: "german",
    value: "de",
    icon: <img src={FlagGermany.src} alt={"DE"} />,
  },
  {
    label: "french",
    value: "fr",
    icon: <img src={FlagFrance.src} alt={"FR"} />,
  },
  {
    label: "dutch",
    value: "nl",
    icon: <img src={FlagNetherlands.src} alt={"NL"} />,
  },
  {
    label: "swedish",
    value: "sv",
    icon: <img src={FlagSweden.src} alt={"SE"} />,
  },
  {
    label: "russian",
    value: "ru",
    icon: <img src={FlagRussia.src} alt={"RU"} />,
  },
  {
    label: "turkish",
    value: "tr",
    icon: <img src={FlagTurkey.src} alt={"TR"} />,
  },
  {
    label: "spanish",
    value: "es",
    icon: <img src={FlagSpain.src} alt={"ES"} />,
  },
  {
    label: "polish",
    value: "pl",
    icon: <img src={FlagPoland.src} alt={"PL"} />,
  },
  {
    label: "arabic",
    value: "ar",
    icon: <img src={FlagArabic.src} alt={"AR"} />,
  },
  {
    label: "ukrainian",
    value: "uk",
    icon: <img src={FlagUkraine.src} alt={"UK"} />,
  },
  {
    label: "italian",
    value: "it",
    icon: <img src={FlagItaly.src} alt={"IT"} />,
  },
];

export const LanguageSelect = (props: LanguageSelectProps) => {
  const {
    onLanguageChange,
    redirectRouterArgs,
    availableLanguages,
    ...selectProps
  } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const t = useTranslations();
  let { availableLocales } = useLocales();

  if (availableLanguages) {
    availableLocales = availableLanguages;
  }

  const { locale, pathname, query } = router;
  const storedLocale = locale;

  // Memoize filtered locales
  const optionCountryLocales = useMemo(
    () =>
      languageOptions().filter((item) => availableLocales.includes(item.value)),
    [availableLocales]
  );

  let selectedCountry = languageOptions().filter(
    (item) => item.value === storedLocale
  )[0];
  selectedCountry = selectedCountry ?? optionCountryLocales[0];

  const applyLanguage = useCallback(
    (language: string) => {
      dayjs.locale(language);
      dispatch(setLanguage(language));
      dispatch(forceRefresh());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!availableLocales.includes(locale)) {
      applyLanguage(availableLocales[0]);
      router
        .push({ pathname, query }, undefined, {
          locale: availableLocales[0],
        })
        .then(() => {
          if (onLanguageChange) {
            onLanguageChange();
          }
        });
    }
  }, [
    applyLanguage,
    locale,
    selectedCountry,
    pathname,
    query,
    router,
    availableLocales,
    onLanguageChange,
    dispatch,
  ]);
  const [value, setValue] = useState<string>(selectedCountry.value);

  const handleOptionSelect = (event: SelectChangeEvent<unknown>) => {
    const v = event.target.value as typeof value;
    setValue(v);
    // Setting the 'lang' attribute on the root 'html' which is necessary for the css property 'hyphens:auto'
    if (window && document) {
      document.documentElement.lang = v;
      document.cookie = `NEXT_LOCALE=${v}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    }
    applyLanguage(v);
    const routerArgs = redirectRouterArgs ?? { pathname, query };
    router
      .push(routerArgs, undefined, {
        locale: v,
      })
      .then(() => {
        if (onLanguageChange) {
          onLanguageChange();
        }
      });
  };

  return (
    <Select
      value={locale}
      onChange={handleOptionSelect}
      placeholder="Action"
      size="small"
      {...selectProps}
    >
      {optionCountryLocales.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          <ListItemIcon
            sx={{
              height: 12,
              minWidth: 20,
              maxWidth: 20,
              marginRight: 1,
              verticalAlign: "text-bottom",
              width: "fit-content",
            }}
          >
            {option.icon}
          </ListItemIcon>
          <ListItemText
            sx={{ display: "inline-flex", margin: 0 }}
            primary={t(option.label)}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
