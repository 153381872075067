import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  Subject,
} from "rxjs";
import { switchMap, catchError, debounceTime, concatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../../constants";
import { errorMessage } from "../../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
import { Action } from "redux";
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from "store/actions/snackbar";
import {
  deleteDocumentFulfilled,
  fetchDocument,
  fetchDocumentDownloadFailed,
  fetchDocumentDownloadFulfilled,
  fetchDocumentFailed,
  fetchDocumentFulfilled,
  fetchDocumentsInfo,
  fetchDocumentsInfoFulfilled,
  fetchUserConversations,
  fetchUserConversationsFailed,
  fetchUserConversationsFulfilled,
  setDocument,
  setDocumentsCount,
  setDocumentsInfo,
  setDocumentsUsage,
  setDownloadDocument,
  setSelectedCopilotChat,
  setUserConversations,
  updateDocumentFulfilled,
} from "store/actions/smartDoc/documents";
import { fetchCollections } from "store/actions/smartDoc/collections";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_AGENT_HOST, REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const uploadDocumentEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.UPLOAD_DOCUMENT),
    debounceTime(0),
    switchMap((action: any) => {
      console.log('[uploadDocumentEpic] Starting upload for:', action.payload.file.name);
      const body = new FormData();
      body.append("file", action.payload.file);
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents`,
        method: "POST",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body,
      }).pipe(
        concatMap((response) => {
          console.log('[uploadDocumentEpic] Upload successful for:', action.payload.file.name);
          return of(showSuccessSnackbar("Snackbars.documentUploadSuccess"));
        }),
        catchError((error) => {
          console.error('[uploadDocumentEpic] Upload failed for:', action.payload.file.name, error);
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentUploadError")
          );
        })
      );
    })
  );
};

export const updateDocumentEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_DOCUMENT),
    debounceTime(500),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          return of(
            updateDocumentFulfilled(),
            fetchDocumentsInfo()
            // showSuccessSnackbar("Snackbars.documentUpdateSuccess")
          );
        }),
        catchError((error) =>
          of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentUpdateError")
          )
        )
      );
    })
  );
};

export const fetchDocumentsCountEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_DOCUMENTS_COUNT),
    debounceTime(0),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/count`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setDocumentsCount(response.response));
        }),
        catchError((error) => {
          return of(setDocumentsCount(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchDocumentsInfoEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_DOCUMENTS_INFO),
    debounceTime(500),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/items`,
        method: "GET",
        headers: {
          // "Content-Type": "application/json"
          "Content-Type": "application/gzip",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            fetchDocumentsInfoFulfilled(),
            setDocumentsInfo(response.response.result.documents.items)
          );
        }),
        catchError((error) => {
          return of(setDocumentsInfo(null), errorMessage(error.message));
        })
      );
    })
  );
};
export const fetchDocumentsUsageEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_DOCUMENTS_USAGE),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/usage`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setDocumentsUsage(response.response));
        }),
        catchError((error) => {
          return of(setDocumentsUsage(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchDocumentEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_DOCUMENT),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/${action.document_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          if (action.hideSnackbar) {
            return of(
              setDocument(response.response.result),
              fetchDocumentFulfilled()
            );
          } else if (action.decodedSegment && action.pageNumber) {
            return of(
              setDocument(response.response.result),
              fetchDocumentFulfilled(
                action.decodedSegment,
                action.pageNumber,
                action.metadata
              )
              // showSuccessSnackbar("Snackbars.documentFetchSuccess", {
              //   vertical: "top",
              //   horizontal: "right",
              // })
            );
          } else {
            return of(
              setDocument(response.response.result),
              fetchDocumentFulfilled()
              // showSuccessSnackbar("Snackbars.documentFetchSuccess", {
              //   vertical: "top",
              //   horizontal: "right",
              // })
            );
          }
        }),
        catchError((error) => {
          if (action.hideSnackbar) {
            return of(setDocument(null), fetchDocumentFailed(error.message));
          } else {
            return of(
              setDocument(null),
              fetchDocumentFailed(error.message),
              showErrorSnackbar("Snackbars.documentFetchError", {
                vertical: "top",
                horizontal: "right",
              })
            );
          }
        })
      );
    })
  );
};

export const deleteDocumentEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_DOCUMENT),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/${action.document_id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          //dispatch(fetchDocumentsInfo)
          return of(
            deleteDocumentFulfilled(),
            // showSuccessSnackbar("Snackbars.documentDeletionSuccess"),
            fetchDocumentsInfo()
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentDeletionError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};
export const deleteDocumentsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_DOCUMENTS),
    debounceTime(100),
    switchMap((action: any) => {
      const payloadBody = { document_ids: action.document_ids };
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: payloadBody,
      }).pipe(
        concatMap((response) => {
          //dispatch(fetchDocumentsInfo)
          return of(
            deleteDocumentFulfilled(),
            // showSuccessSnackbar("Snackbars.documentDeletionSuccess"),
            fetchDocumentsInfo(),
            fetchCollections()
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentDeletionError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};
export const downloadDocumentEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.DOWNLOAD_DOCUMENT),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/document/${state$.value.tenant}/${action.document_id}/download?as_pdf=true`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
          Accept: "application/pdf",
        },
        responseType: "blob",
      }).pipe(
        concatMap((response) => {
          return of(
            setDownloadDocument(response.response),
            fetchDocumentDownloadFulfilled()
            // showSuccessSnackbar("Snackbars.documentDownloadSuccess", {
            //   vertical: "top",
            //   horizontal: "right",
            // })
          );
        }),
        catchError((error) => {
          return of(
            setDownloadDocument(null),
            fetchDocumentDownloadFailed(error.message),
            showErrorSnackbar("Snackbars.documentDownloadError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};

export const documentSearchEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.DOCUMENT_SEARCH),
    debounceTime(500),
    switchMap((action: any) => {
      const payloadBody = { question: action.payload };
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/search`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: payloadBody,
      }).pipe(
        concatMap((response) => {
          return of(setDocumentsInfo(response.response.result));
        }),
        catchError((error) =>
          of(setDocumentsInfo(null), errorMessage(error.message))
        )
      );
    })
  );
};

export const fetchUserConversationsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_USER_CONVERSATIONS),
    debounceTime(100),
    switchMap((action: any) => {
      let url;
      if (action.llm_model) {
        url = `${REACT_APP_ACTION_HOST}/conversations/my-conversations?llm_model=${action.llm_model}`;
      } else if (action.documentId) {
        url = `${REACT_APP_ACTION_HOST}/conversations/my-conversations?document_id=${action.documentId}`;
      } else if (action.collectionId) {
        url = `${REACT_APP_ACTION_HOST}/conversations/my-conversations?collection_id=${action.collectionId}`;
      } else {
        url = `${REACT_APP_ACTION_HOST}/conversations/my-conversations`;
      }

      // Add language parameter to URL if provided
      if (action.language) {
        url += url.includes("?")
          ? `&language=${action.language}`
          : `?language=${action.language}`;
      }

      return ajax({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setUserConversations(response.response.result),
            fetchUserConversationsFulfilled()
            // showSuccessSnackbar("Snackbars.documentConversationsFetchSuccess", {
            //   vertical: "top",
            //   horizontal: "right",
            // })
          );
        }),
        catchError((error) => {
          return of(
            setUserConversations(null),
            fetchUserConversationsFailed(error.message),
            showErrorSnackbar("Snackbars.documentConversationsFetchError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};

export const upsertUserConversationsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
      language: string;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.UPSERT_USER_CONVERSATIONS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/my-conversations`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          const { documentId, collectionId, editChatId, llm_model } = action;

          let snackBarMessage = "Snackbars.documentConversationsUpsertSuccess";
          if (editChatId) {
            snackBarMessage = "Snackbars.documentConversationsEditSuccess";
          }

          if (documentId || collectionId || llm_model) {
            let fetchUserConversationsPayload: {} | any = null;
            if (llm_model) {
              fetchUserConversationsPayload = {
                llm_model: llm_model,
                language: state$.value.language,
              };
            }
            if (documentId) {
              fetchUserConversationsPayload = {
                documentId: documentId,
                language: state$.value.language,
              };
            } else if (collectionId) {
              fetchUserConversationsPayload = {
                collectionId: collectionId,
                language: state$.value.language,
              };
            }
            return of(fetchUserConversations(fetchUserConversationsPayload));
          } else {
            return of(
              fetchUserConversations({ language: state$.value.language })
            );
          }
        }),
        catchError((error) => {
          let snackBarMessage = "Snackbars.documentConversationsUpsertError";
          if (action.editChatId) {
            snackBarMessage = "Snackbars.documentConversationsEditError";
          }
          return of(
            errorMessage(error.message),
            showErrorSnackbar(snackBarMessage, {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};

export const manuallyIndexDocumentEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.MANUALLY_INDEX_DOCUMENT),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/${action.payload}/index`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            fetchDocumentsInfo(),
            showInfoSnackbar("Snackbars.manuallyIndexDocumentSuccess", {
              vertical: "top",
              horizontal: "right",
            })
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.manuallyIndexDocumentError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};

export const manuallyIndexDocumentsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.MANUALLY_INDEX_DOCUMENTS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/documents/index`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            fetchDocumentsInfo(),
            showInfoSnackbar("Snackbars.manuallyIndexDocumentsSuccess", {
              vertical: "top",
              horizontal: "right",
            })
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.manuallyIndexDocumentsError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};
