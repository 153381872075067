import { of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  debounceTime,
  concatMap,
  mergeMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import {
  setDialogueGraphData,
  saveDialogueGraphDataFulfilled,
  publishDialogueGraphDataFulfilled,
  setActionNodeTemplate,
  setDialogueGraphs,
  setDialogueUrls,
  createDialogueGraph,
  revertDialogueGraphDataFulfilled,
  fetchDialogueGraph,
  setGraphId,
  setDialogueGraphVariables,
  fetchDialogueGraphVariables,
  setDialogueGraphBlueprints,
  deleteDialogueGraphsFulfilled,
  saveDialogueGraphInfoFulfilled,
  setDialogueGraphChannel,
  clearGraphHistory,
  setDialogueGraphInfo,
  setProactiveMessage,
  setEedNotificationData,
  saveProactiveMessageFulfilled,
  saveEedNotificationDataFulfilled,
  trainDialogueGraphDataFulfilled,
  publishDialogueGraphError,
  saveDialogueGraphError,
  setDialogueGraphPublishedAt,
  setDialogueGraphDataPublishedStatus,
} from "../actions/dialogues";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { isEdge, isNode } from "../util";
import getConfig from "../../lib/config";
import { forceRefresh } from "../actions/common";
import { showErrorSnackbar, showSuccessSnackbar } from "../actions/snackbar";
import * as actions from "../actions";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

const defaultChannel = "web";

const channelList =
  "web,voice,google_business_messages,whatsapp,facebook,emails";

export const fetchDialoguesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/gzip",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          let latest_data = JSON.parse(response?.response?.latest_data);
          let info_data = response?.response;
          let channel = response?.response?.channel;
          let published_change_at = response?.response?.published_change_at;
          let latest_change_at = response?.response?.latest_change_at;
          let publishedState = "unpublished";
          if (published_change_at >= latest_change_at) {
            publishedState = "success";
          }
          if (latest_data) {
            return of(
              clearGraphHistory(),
              fetchDialogueGraphVariables(),
              setDialogueGraphData(latest_data.nodes.concat(latest_data.links)),
              setDialogueGraphInfo(info_data),
              setDialogueGraphChannel(channel),
              setDialogueGraphPublishedAt(published_change_at),
              setDialogueGraphDataPublishedStatus(publishedState)
            );
          }
          return of(clearGraphHistory(), setDialogueGraphData([]));
        }),
        catchError((error) => {
          if (error.status === 404) {
            return of(createDialogueGraph());
          }
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const createDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_DIALOGUE_GRAPH),
    mergeMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs?graph_label=dev_${new Date().getTime()}&language=${
          state$.value.language
        }&channel=${action.channel || defaultChannel}`,
        method: "POST",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        mergeMap((response) => {
          const createdId = JSON.parse(response?.response?.graph_id);

          // Always dispatch setGraphId and setDialogueGraphPublishedAt
          const actionsToDispatch = [
            setGraphId(createdId),
            setDialogueGraphPublishedAt(null),
          ];

          // If name is provided, dispatch saveDialogueGraphInfo with the name
          if (action.name) {
            actionsToDispatch.push(
              actions.dialogues.saveDialogueGraphInfo(createdId, {
                latest_label: action.name,
                published_label: action.name,
              })
            );
          }

          // Fetch the updated list of dialogue graphs
          actionsToDispatch.push(
            actions.dialogues.fetchDialogueGraphs(action.channel || "web")
          );

          return actionsToDispatch;
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const createFormDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_FORM_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/form_graphs?language=${
          state$.value.language
        }&channel=${action.channel || defaultChannel}
                &form_id=${
                  action.form_id || defaultChannel
                }&dialogue_graph_id=${action.dialogue_graph_id || null}`,
        method: "POST",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      let createdId = JSON.parse(response?.response?.graph_id);
      return setGraphId(createdId);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const copyDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.COPY_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax.post(
        `${REACT_APP_ACTION_HOST}/graphs/${action.sourceId}/copy?language=${state$.value.language}`,
        null,
        {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        }
      );
    }),
    map((response) => {
      let createdId = JSON.parse(response?.response?.graph_id);
      return setGraphId(createdId);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const copyDialogueGraphTemplateEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.COPY_DIALOGUE_GRAPH_TEMPLATE),
    mergeMap((action) => {
      return ajax
        .post(
          `${REACT_APP_ACTION_HOST}/graphs/templates/${action.sourceId}/copy?language=${state$.value.language}`,
          null,
          {
            "Content-Type": "application/json",
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          }
        )
        .pipe(
          mergeMap((response) => {
            const createdId = JSON.parse(response?.response?.graph_id);

            // Always dispatch setGraphId
            const actionsToDispatch = [setGraphId(createdId)];

            // Conditionally dispatch saveDialogueGraphInfo only if data is present
            if (action.data) {
              actionsToDispatch.push(
                actions.dialogues.saveDialogueGraphInfo(createdId, action.data)
              );
            }

            actionsToDispatch.push(
              actions.dialogues.fetchDialogueGraphs(
                action?.data?.channel || "web"
              )
            );
            actionsToDispatch.push(
              actions.dialogues.publishDialogueGraphData()
            );

            return actionsToDispatch;
          }),
          catchError((error) => of(errorMessage(error.message)))
        );
    })
  );

export const deleteDialogueGraphsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.DELETE_DIALOGUE_GRAPHS),
    switchMap((action) =>
      ajax
        .delete(`${REACT_APP_ACTION_HOST}/graphs/${action.graphIds}`, {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          concatMap((response) => {
            let status = response.status === 200 ? "success" : "error";
            return of(
              deleteDialogueGraphsFulfilled(status),
              setDialogueGraphs(
                state$.value.dialogueGraphs.filter(
                  (graph) => !action.graphIds.includes(graph.id)
                )
              )
            );
          }),
          catchError((error) => of(errorMessage(error.message)))
        )
    )
  );

export const fetchAllDialoguesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPHS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/info?languages=${
          state$.value.language
        }&channels=${action.channel || channelList}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setDialogueGraphs(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchAllUrlsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_URLS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/urls/info?languages=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setDialogueUrls(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchAllDialogueBlueprintsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH_BLUEPRINTS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/templates/info?languages=${
          state$.value.language
        }&created_by=SYSTEM&channels=${action.channel || defaultChannel}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setDialogueGraphBlueprints(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const saveDialogueGraphData = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.SAVE_DIALOGUE_GRAPH),
    debounceTime(200),
    switchMap((action) => {
      let formattedGraphData = {
        directed: true,
        multigraph: true,
        graph: {},
        nodes: state$.value.dialogueGraphData.present.filter((el) =>
          isNode(el)
        ),
        links: state$.value.dialogueGraphData.present.filter((el) =>
          isEdge(el)
        ),
      };

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}?language=${state$.value.language}&train=true`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
        body: {
          latest_data: JSON.stringify(formattedGraphData),
        },
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";
          let draftId = response?.response?.draft_id;
          let latestChangeAt = response?.response?.latest_change_at;

          let actions = [
            saveDialogueGraphDataFulfilled(status),
            fetchDialogueGraphVariables(),
          ];

          if (draftId && state$.value.dialogueGraphInfo?.draft_id !== draftId) {
            actions.push(
              setDialogueGraphInfo({
                ...(state$.value.dialogueGraphInfo || {}),
                draft_id: state$.value.dialogueGraphInfo?.draft_id || draftId,
              })
            );
          }
          if (latestChangeAt) {
            actions.push(
              setDialogueGraphInfo({
                ...(state$.value.dialogueGraphInfo || {}),
                latest_change_at: latestChangeAt,
              })
            );
            actions.push(
              setDialogueGraphs(
                state$.value.dialogueGraphs.map((e) => {
                  if (e.id === state$.value.dialogueGraphInfo.id)
                    return { ...e, latest_change_at: latestChangeAt };
                  return e;
                })
              )
            );
          }

          return of(...actions);
        }),
        catchError((error) =>
          of(
            saveDialogueGraphError(error.message),
            saveDialogueGraphDataFulfilled("error")
          )
        )
      );
    })
  );
};

export const saveDialogueGraphInfo = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.SAVE_DIALOGUE_GRAPH_INFO),
    debounceTime(2000),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${action.graphId}?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";

          return of(
            saveDialogueGraphInfoFulfilled(status),
            setDialogueGraphInfo(action.payload),
            setDialogueGraphs(
              state$.value.dialogueGraphs.map((e) => {
                if (e.id === action.graphId) return { ...e, ...action.payload };
                return e;
              })
            )
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );
};

export const publishDialogueGraphData = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.PUBLISH_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/publish?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        concatMap((response) => {
          let status = response.status === 200 ? "success" : "error";
          const published_at = response?.response?.published_at;
          return of(
            publishDialogueGraphDataFulfilled(status),
            setDialogueGraphPublishedAt(published_at),
            showSuccessSnackbar("Snackbars.publishSuccess")
          );
        }),
        catchError((error) =>
          of(
            publishDialogueGraphError(error.message),
            showErrorSnackbar("Snackbars.publishError")
          )
        )
      );
    })
  );
};

export const trainDialogueGraphData = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.TRAIN_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/train?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        map((response) => {
          let status = response.status === 200 ? "success" : "error";
          return trainDialogueGraphDataFulfilled(status);
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );
};

export const revertDialogueGraphData = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.REVERT_DIALOGUE_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/revert?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      });
    }),
    map((response) => {
      let status = response.status === 200 ? "success" : "error";
      return revertDialogueGraphDataFulfilled(status);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
};

export const fetchActionNodeTemplate = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_ACTION_NODE_TEMPLATE),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/action/${getActionNameForVariant(
          action.nodeType,
          action.nodeVariant
        )}/node?language=${state$.value.language}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        map((response) => {
          return setActionNodeTemplate(response.response);
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

const getActionNameForVariant = (type, variant) => {
  return `dialogue_editor_${variant}_form`;
};

export const clearDialogueGraphEpic = (action$) =>
  action$.pipe(
    ofType(ActionConstants.CLEAR_DIALOGUE_GRAPH),
    map(() => {
      return setDialogueGraphData([]);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const clearDialogueGraphsEpic = (action$) =>
  action$.pipe(
    ofType(ActionConstants.CLEAR_DIALOGUE_GRAPHS),
    map(() => {
      return setDialogueGraphData([]);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const refetchDialogueGraphEpic = (action$) =>
  action$.pipe(
    ofType(ActionConstants.REVERT_DIALOGUE_GRAPH_FULFILLED),
    map(() => {
      return fetchDialogueGraph();
    }),
    catchError((error) => of(errorMessage(error.message)))
  );

export const fetchDialogueVariablesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_GRAPH_VARIABLES),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/variables?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/gzip",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      let variables = response?.response?.result;
      return setDialogueGraphVariables(variables);
    }),
    catchError((error) => {
      if (error.status === 404) {
        return of(setDialogueGraphVariables([]));
      }
      return of(errorMessage(error.message));
    })
  );

export const fetchProactiveMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_PROACTIVE_MESSAGE),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/proactive_message`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setProactiveMessage(response?.response);
        }),
        catchError((error) => {
          return of(
            setProactiveMessage({ active: false }),
            errorMessage(error.message)
          );
        })
      );
    })
  );

export const saveProactiveMessageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SAVE_PROACTIVE_MESSAGE),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/proactive_message`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        map((response) => {
          return saveProactiveMessageFulfilled();
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const saveEedNotificationData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SAVE_EED_NOTIFICATION_DATA),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/notifications`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        map((response) => {
          return saveEedNotificationDataFulfilled();
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );

export const fetchEedNotificationData = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_EED_NOTIFICATION_DATA),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/notifications`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setEedNotificationData(response?.response);
        }),
        catchError((error) => {
          return of(
            setEedNotificationData({ day_of_month: 1 }),
            errorMessage(error.message)
          );
        })
      );
    })
  );

export const setupAutoDialogueGraphEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.SETUP_AUTO_GRAPH),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/setup?should_publish=${action.payload.publish}&language=${state$.value.language}&channel=${action.payload.channel}&generate_faq_graph=${action.payload.generate_faq_graph}&generate_ticket_graph=${action.payload.generate_ticket_graph}&main_label=${action.payload.main_label}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload.body,
      }).pipe(
        concatMap((response) => {
          let createdId = JSON.parse(response?.response?.graph_id);
          return of(forceRefresh(), setGraphId(createdId));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
