import Actions from "../constants";

export const setJumpGraphs = (currentState, action) => {
  if (action.type !== Actions.SET_JUMP_GRAPHS) {
    return currentState;
  }

  return action.payload;
};

export const setGlobalGraphs = (currentState, action) => {
  if (action.type !== Actions.SET_GLOBAL_GRAPHS) {
    return currentState;
  }

  return action.payload;
};
