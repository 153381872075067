export default {
  FETCH_SUBGROUPS: "FETCH_SUBGROUPS",
  SET_SUBGROUPS: "SET_SUBGROUPS",
  SET_DRAWER_STATE: "SET_DRAWER_STATE",
  SET_COPILOT_DRAWER_STATE: "SET_COPILOT_DRAWER_STATE",
  SET_CONVERSATION_SIDEBAR_STATE: "SET_CONVERSATION_SIDEBAR_STATE",
  SET_UI_PREFERENCES: "SET_UI_PREFERENCES",
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_TENANT: "SET_TENANT",
  FETCH_FAILURE: "Fetch Failure",
  FETCH_DIALOGUE_GRAPH: "Fetch Dialogue Graph",
  FETCH_DIALOGUE_GRAPHS: "FETCH_DIALOGUE_GRAPHS",
  FETCH_DIALOGUE_URLS: "FETCH_DIALOGUE_URLS",
  FETCH_DIALOGUE_GRAPH_BLUEPRINTS: "FETCH_DIALOGUE_GRAPH_BLUEPRINTS",
  SET_DIALOGUE_GRAPH_BLUEPRINTS: "SET_DIALOGUE_GRAPH_BLUEPRINTS",
  SET_DIALOGUE_GRAPHS: "SET_DIALOGUE_GRAPHS",
  SET_DIALOGUE_URLS: "SET_DIALOGUE_URLS",
  CREATE_DIALOGUE_GRAPH: "CREATE_DIALOGUE_GRAPH",
  CREATE_FORM_DIALOGUE_GRAPH: "CREATE_FORM_DIALOGUE_GRAPH",
  COPY_DIALOGUE_GRAPH: "COPY_DIALOGUE_GRAPH",
  COPY_DIALOGUE_GRAPH_TEMPLATE: "COPY_DIALOGUE_GRAPH_TEMPLATE",
  DELETE_DIALOGUE_GRAPHS: "DELETE_DIALOGUE_GRAPHS",
  DELETE_DIALOGUE_GRAPHS_FULFILLED: "DELETE_DIALOGUE_GRAPHS_FULFILLED",
  COPY_DIALOGUE_GRAPH_FULFILLED: "COPY_DIALOGUE_GRAPH_FULFILLED",
  SET_GRAPH_ID: "SET_GRAPH_ID",
  FETCH_ALL_DIALOGUE_GRAPH: "Fetch all Dialogue Graph",
  SET_ALL_DIALOGUE_GRAPH: "Set all Dialogue Graph",
  SET_DIALOGUE_GRAPH: "SET_DIALOGUE_GRAPH",
  SET_DIALOGUE_GRAPH_INFO: "SET_DIALOGUE_GRAPH_INFO",
  SET_DIALOGUE_GRAPH_CHANNEL: "SET_DIALOGUE_GRAPH_CHANNEL",
  SET_DIALOGUE_GRAPH_SAVED_STATUS: "SET_DIALOGUE_GRAPH_SAVED_STATUS",
  SAVE_DIALOGUE_GRAPH: "SAVE_DIALOGUE_GRAPH",
  SAVE_DIALOGUE_GRAPH_INFO: "SAVE_DIALOGUE_GRAPH_INFO",
  SAVE_DIALOGUE_GRAPH_FULFILLED: "SAVE_DIALOGUE_GRAPH_FULFILLED",
  SAVE_DIALOGUE_GRAPH_INFO_FULFILLED: "SAVE_DIALOGUE_GRAPH_INFO_FULFILLED",
  SAVE_DIALOGUE_GRAPH_ERROR: "SAVE_DIALOGUE_GRAPH_ERROR",
  PUBLISH_DIALOGUE_GRAPH_ERROR: "PUBLISH_DIALOGUE_GRAPH_ERROR",
  SAVE_DIALOGUE_GRAPH_FULFILLED_AT: "SAVE_DIALOGUE_GRAPH_FULFILLED_AT",
  PUBLISH_DIALOGUE_GRAPH: "PUBLISH_DIALOGUE_GRAPH",
  PUBLISH_DIALOGUE_GRAPH_FULFILLED: "PUBLISH_DIALOGUE_GRAPH_FULFILLED",
  TRAIN_DIALOGUE_GRAPH: "TRAIN_DIALOGUE_GRAPH",
  TRAIN_DIALOGUE_GRAPH_FULFILLED: "TRAIN_DIALOGUE_GRAPH_FULFILLED",
  REVERT_DIALOGUE_GRAPH: "REVERT_DIALOGUE_GRAPH",
  REVERT_DIALOGUE_GRAPH_FULFILLED: "REVERT_DIALOGUE_GRAPH_FULFILLED",
  PUBLISH_DIALOGUE_GRAPH_FULFILLED_AT: "PUBLISH_DIALOGUE_GRAPH_FULFILLED_AT",
  SET_KEYCLOAK_TOKEN: "Set Keycloak Token",
  ADD_DIALOGUE_ELEMENT: "ADD_DIALOGUE_ELEMENT",
  ADD_DIALOGUE_ELEMENT_FULFILLED: "ADD_DIALOGUE_ELEMENT_FULFILLED",
  ADD_DIALOGUE_ELEMENT_REJECTED: "ADD_DIALOGUE_ELEMENT_REJECTED",
  DELETE_DIALOGUE_ELEMENT: "DELETE_DIALOGUE_ELEMENT",
  DELETE_DIALOGUE_ELEMENT_FULFILLED: "DELETE_DIALOGUE_ELEMENT_FULFILLED",
  DELETE_DIALOGUE_ELEMENT_REJECTED: "DELETE_DIALOGUE_ELEMENT_REJECTED",
  FORCE_REFRESH: "FORCE_REFRESH",
  CLEAR_DIALOGUE_GRAPH: "CLEAR_DIALOGUE_GRAPH",
  CLEAR_DIALOGUE_GRAPHS: "CLEAR_DIALOGUE_GRAPHS",
  CLEAR_ALL_DIALOGUE_GRAPH: "CLEAR_ALL_DIALOGUE_GRAPH",
  FETCH_SYNONYMS: "FETCH_SYNONYMS",
  SET_SYNONYMS: "SET_SYNONYMS",
  ADD_UTTERANCE: "ADD_UTTERANCE",
  ADD_UTTERANCE_FULFILLED: "ADD_UTTERANCE_FULFILLED",
  FETCH_ACTION_NODE_TEMPLATE: "FETCH_ACTION_NODE_TEMPLATE",
  SET_ACTION_NODE_TEMPLATE: "SET_ACTION_NODE_TEMPLATE",
  FETCH_DIALOGUE_GRAPH_VARIABLES: "FETCH_DIALOGUE_GRAPH_VARIABLES",
  SET_DIALOGUE_GRAPH_VARIABLES: "SET_DIALOGUE_GRAPH_VARIABLES",
  FETCH_DIALOGUE_GRAPH_VARIABLES_FULFILLED:
    "FETCH_DIALOGUE_GRAPH_VARIABLES_FULFILLED",
  PATCH_TICKET: "PATCH_TICKET",
  PATCH_TICKET_FULFILLED: "PATCH_TICKET_FULFILLED",
  FETCH_TICKETS: "FETCH_TICKETS",
  FETCH_RENTAL_UNIT_TICKETS: "FETCH_RENTAL_UNIT_TICKETS",
  SET_OPEN_TICKETS: "SET_OPEN_TICKETS",
  SET_SOLVED_TICKETS: "SET_SOLVED_TICKETS",
  SET_IN_PROGRESS_TICKETS: "SET_IN_PROGRESS_TICKETS",
  SET_PENDING_TICKETS: "SET_PENDING_TICKETS",
  SET_FORWARDED_TICKETS: "SET_FORWARDED_TICKETS",
  SET_CLOSED_TICKETS: "SET_CLOSED_TICKETS",
  SELECT_TICKETS: "SELECT_TICKETS",
  SET_TICKETS: "SET_TICKETS",
  PATCH_RENTAL_UNIT_TICKETS: "PATCH_RENTAL_UNIT_TICKETS",
  PATCH_RENTAL_UNIT_TICKETS_FULFILLED: "PATCH_RENTAL_UNIT_TICKETS_FULFILLED",
  FETCH_RENTAL_UNIT_TENANTS: "FETCH_RENTAL_UNIT_TENANTS",
  FETCH_RENTAL_UNIT_ARTICLES: "FETCH_RENTAL_UNIT_ARTICLES",
  SET_RENTAL_UNIT_ARTICLES: "SET_RENTAL_UNIT_ARTICLES",
  SET_RENTAL_UNIT_TENANTS: "SET_RENTAL_UNIT_TENANTS",
  FETCH_RENTAL_UNIT_TICKET_COUNT_BY_STATUS: "FETCH_TICKET_COUNT_BY_STATUS",
  SET_RENTAL_UNIT_TICKET_COUNT_BY_STATUS:
    "SET_RENTAL_UNIT_TICKET_COUNT_BY_STATUS",
  FETCH_FAQS: "FETCH_FAQS",
  SET_FAQS: "SET_FAQS",
  FETCH_TRAIN_STATUS: "FETCH_TRAIN_STATUS",
  SET_TRAIN_STATUS: "SET_TRAIN_STATUS",
  FETCH_JUMP_GRAPHS: "FETCH_JUMP_GRAPHS",
  SET_JUMP_GRAPHS: "SET_JUMP_GRAPHS",
  FETCH_GLOBAL_GRAPHS: "FETCH_GLOBAL_GRAPHS",
  SET_GLOBAL_GRAPHS: "SET_GLOBAL_GRAPHS",
  FETCH_CONVERSATIONS: "FETCH_CONVERSATIONS",
  SET_CONVERSATIONS: "SET_CONVERSATIONS",
  SET_CONVERSATIONS_COUNT: "SET_CONVERSATIONS_COUNT",
  SET_CONVERSATIONS_COUNT_ASSIGNED: "SET_CONVERSATIONS_COUNT_ASSIGNED",
  SET_CONVERSATIONS_COUNT_UNASSIGNED: "SET_CONVERSATIONS_COUNT_UNASSIGNED",
  FETCH_CONVERSATION: "FETCH_CONVERSATION",
  SET_CONVERSATION: "SET_CONVERSATION",
  FETCH_CONVERSATION_TRACKER: "FETCH_CONVERSATION_TRACKER",
  SET_CONVERSATION_TRACKER: "SET_CONVERSATION_TRACKER",
  SET_AGENT_ASSIST_RESPONSE: "SET_AGENT_ASSIST_RESPONSE",
  FETCH_CONVERSATION_ATTACHMENTS: "FETCH_CONVERSATION_ATTACHMENTS",
  SET_CONVERSATION_ATTACHMENTS: "SET_CONVERSATION_ATTACHMENTS",
  FETCH_ATTACHMENT: "FETCH_ATTACHMENT",
  SET_ATTACHMENT_FILE: "SET_ATTACHMENT_FILE",
  FETCH_TICKET_ANSWER: "FETCH_TICKET_ANSWER",
  SET_TICKET_ANSWER: "SET_TICKET_ANSWER",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_FULFILLED: "UPLOAD_FILE_FULFILLED",
  FETCH_FILES: "FETCH_FILES",
  FETCH_FILES_FULFILLED: "FETCH_FILES_FULFILLED",
  UPLOAD_THEME_CONFIG: "UPLOAD_THEME_CONFIG",
  UPLOAD_THEME_CONFIG_FULFILLED: "UPLOAD_THEME_CONFIG_FULFILLED",
  FETCH_THEME_CONFIG: "FETCH_THEME_CONFIG",
  FETCH_THEME_CONFIG_FULFILLED: "FETCH_THEME_CONFIG_FULFILLED",
  FETCH_CHANNEL_LIMITATIONS: "FETCH_CHANNEL_LIMITATIONS",
  FETCH_CHANNEL_SHARE_LINKS: "FETCH_CHANNEL_SHARE_LINKS",
  FETCH_CHANNEL_LIMITATIONS_FULFILLED: "FETCH_CHANNEL_LIMITATIONS_FULFILLED",
  SET_CHANNEL_LIMITATIONS: "SET_CHANNEL_LIMITATIONS",
  SET_CHANNEL_SHARE_LINKS: "SET_CHANNEL_SHARE_LINKS",
  UNDO_GRAPH: "UNDO_GRAPH",
  REDO_GRAPH: "REDO_GRAPH",
  CLEAR_GRAPH_HISTORY: "CLEAR_GRAPH_HISTORY",
  FETCH_FACEBOOK_PAGES: "FETCH_FACEBOOK_PAGES",
  SET_FACEBOOK_PAGES: "SET_FACEBOOK_PAGES",
  SET_USED_FACEBOOK_PAGE: "SET_USED_FACEBOOK_PAGE",
  FETCH_USED_FACEBOOK_PAGE: "FETCH_USED_FACEBOOK_PAGE",
  CREATE_FACEBOOK_PAGE: "CREATE_FACEBOOK_PAGE",
  DELETE_LINKED_FACEBOOK_PAGE: "DELETE_LINKED_FACEBOOK_PAGE",
  CREATE_FACEBOOK_PAGE_FULFILLED: "CREATE_FACEBOOK_PAGE_FULFILLED",
  SET_FACEBOOK_TOKEN: "SET_FACEBOOK_TOKEN",
  CREATE_WEB_CHANNEL_CONFIG: "CREATE_WEB_CHANNEL_CONFIG",
  CREATE_WEB_CHANNEL_CONFIG_FULFILLED: "CREATE_WEB_CHANNEL_CONFIG_FULFILLED",
  FETCH_USED_WEB_CHANNEL_CONFIG: "FETCH_USED_WEB_CHANNEL_CONFIG",
  SET_USED_WEB_CHANNEL_CONFIG: "SET_USED_WEB_CHANNEL_CONFIG",
  DELETE_LINKED_WEB_CHANNEL_CONFIG: "DELETE_LINKED_WEB_CHANNEL_CONFIG",
  FETCH_PROACTIVE_MESSAGE: "FETCH_PROACTIVE_MESSAGE",
  SET_PROACTIVE_MESSAGE: "SET_PROACTIVE_MESSAGE",
  SAVE_PROACTIVE_MESSAGE_FULFILLED: "SAVE_PROACTIVE_MESSAGE_FULFILLED",
  SAVE_PROACTIVE_MESSAGE: "SAVE_PROACTIVE_MESSAGE",
  FETCH_CONVERSATION_IDENTITY: "FETCH_CONVERSATION_IDENTITY",
  FETCH_CONVERSATION_IDENTITY_FULFILLED:
    "FETCH_CONVERSATION_IDENTITY_FULFILLED",
  SET_CONVERSATION_IDENTITY: "SET_CONVERSATION_IDENTITY",
  SAVE_TENANT_IDENTITY: "SAVE_TENANT_IDENTITY",
  SET_TENANT_IDENTITY: "SET_TENANT_IDENTITY",
  FETCH_EED_NOTIFICATION_DATA: "FETCH_EED_NOTIFICATION_DATA",
  SET_EED_NOTIFICATION_DATA: "SET_EED_NOTIFICATION_DATA",
  SAVE_EED_NOTIFICATION_DATA: "SAVE_EED_NOTIFICATION_DATA",
  SAVE_EED_NOTIFICATION_DATA_FULFILLED: "SAVE_EED_NOTIFICATION_DATA_FULFILLED",
  CREATE_NOTE: "CREATE_NOTE",
  CREATE_NOTE_FULFILLED: "CREATE_NOTE_FULFILLED",
  FETCH_CANNED_RESPONSES: "FETCH_CANNED_RESPONSES",
  SET_CANNED_RESPONSES: "SET_CANNED_RESPONSES",
  FETCH_CANNED_RESPONSE: "FETCH_CANNED_RESPONSE",
  SET_CANNED_RESPONSE: "SET_CANNED_RESPONSE",
  CREATE_CANNED_RESPONSE: "CREATE_CANNED_RESPONSE",
  CREATE_CANNED_RESPONSE_FULFILLED: "CREATE_CANNED_RESPONSE_FULFILLED",
  SAVE_CANNED_RESPONSE: "SAVE_CANNED_RESPONSE",
  SAVE_CANNED_RESPONSE_FULFILLED: "SAVE_CANNED_RESPONSE_FULFILLED",
  DELETE_CANNED_RESPONSE: "DELETE_CANNED_RESPONSE",
  DELETE_CANNED_RESPONSE_FULFILLED: "DELETE_CANNED_RESPONSE_FULFILLED",
  FETCH_LABELS: "FETCH_LABELS",
  SET_LABELS: "SET_LABELS",
  FETCH_LABEL: "FETCH_LABEL",
  SET_LABEL: "SET_LABEL",
  CREATE_LABEL: "CREATE_LABEL",
  CREATE_LABEL_FULFILLED: "CREATE_LABEL_FULFILLED",
  SAVE_LABEL: "SAVE_LABEL",
  SAVE_LABEL_FULFILLED: "SAVE_LABEL_FULFILLED",
  DELETE_LABEL: "DELETE_LABEL",
  DELETE_LABEL_FULFILLED: "DELETE_LABEL_FULFILLED",
  FETCH_LABELS_FOR_CONVERSATION: "FETCH_LABELS_FOR_CONVERSATION",
  SET_LABELS_FOR_CONVERSATION: "SET_LABELS_FOR_CONVERSATION",
  ADD_LABEL_TO_CONVERSATION: "ADD_LABEL_TO_CONVERSATION",
  ADD_LABEL_TO_CONVERSATION_FULFILLED: "ADD_LABEL_FULFILLED",
  DELETE_LABEL_FROM_CONVERSATION: "DELETE_LABEL_FROM_CONVERSATION",
  DELETE_LABEL_FROM_CONVERSATION_FULFILLED: "DELETE_LABEL_FROMLFILLED",
  ADD_EVENT: "ADD_EVENT",
  ADD_EVENT_FULFILLED: "ADD_EVENT_FULFILLED",
  FETCH_CASE_STATUS_VALUES: "FETCH_CASE_STATUS_VALUES",
  SET_CASE_STATUS_VALUES: "SET_CASE_STATUS_VALUES",
  CHANGE_CONVERSATION_CASE_STATUS: "CHANGE_CONVERSATION_CASE_STATUS",
  CHANGE_CONVERSATION_CASE_STATUS_FULFILLED:
    "CHANGE_CONVERSATION_CASE_STATUS_FULFILLED",
  FETCH_CONTACTS: "FETCH_CONTACTS",
  SET_CONTACTS: "SET_CONTACTS",
  FETCH_CONTACT: "FETCH_CONTACT",
  SET_CONTACT: "SET_CONTACT",
  CREATE_CONTACT: "CREATE_CONTACT",
  CREATE_CONTACT_FULFILLED: "CREATE_CONTACT_FULFILLED",
  SAVE_CONTACT: "SAVE_CONTACT",
  SAVE_CONTACT_FULFILLED: "SAVE_CONTACT_FULFILLED",
  DELETE_CONTACT: "DELETE_CONTACT",
  DELETE_CONTACT_FULFILLED: "DELETE_CONTACT_FULFILLED",
  MERGE_CONTACT: "MERGE_CONTACT",
  MERGE_CONTACT_FULFILLED: "MERGE_CONTACT_FULFILLED",
  SYNC_CONTACT: "SYNC_CONTACT",
  SYNC_CONTACT_FULFILLED: "SYNC_CONTACT_FULFILLED",
  FETCH_USERS: "FETCH_USERS",
  SET_USERS: "SET_USERS",
  FETCH_USER: "FETCH_USER",
  SET_USER: "SET_USER",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_FULFILLED: "CREATE_USER_FULFILLED",
  SAVE_USER: "SAVE_USER",
  SAVE_USER_FULFILLED: "SAVE_USER_FULFILLED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_FULFILLED: "DELETE_USER_FULFILLED",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  RESET_USER_PASSWORD_FULFILLED: "RESET_USER_PASSWORD_FULFILLED",
  RESEND_USER_EMAIL_VERIFICATION: "RESEND_USER_EMAIL_VERIFICATION",
  RESEND_USER_EMAIL_VERIFICATION_FULFILLED:
    "RESEND_USER_EMAIL_VERIFICATION_FULFILLED",
  CHANGE_USER_ONLINE_STATUS: "CHANGE_USER_ONLINE_STATUS",
  SET_USER_ONLINE_STATUS: "SET_USER_ONLINE_STATUS",
  CHANGE_USER_NOTIFICATION_PAUSE: "CHANGE_USER_NOTIFICATION_PAUSE",
  SET_USER_NOTIFICATION_PAUSE: "SET_USER_NOTIFICATION_PAUSE",
  FETCH_USER_SETTINGS: "FETCH_USER_SETTINGS",
  SET_USER_SETTINGS: "SET_USER_SETTINGS",
  SAVE_USER_SETTINGS: "SAVE_USER_SETTINGS",
  SAVE_USER_SETTINGS_FULFILLED: "SAVE_USER_SETTINGS_FULFILLED",
  UPDATE_CONVERSATION: "UPDATE_CONVERSATION",
  UPDATE_CONVERSATION_FULFILLED: "UPDATE_CONVERSATION_FULFILLED",
  UPDATE_CONVERSATION_READ: "UPDATE_CONVERSATION_READ",
  UPDATE_CONVERSATION_READ_FULFILLED: "UPDATE_CONVERSATION_READ_FULFILLED",
  FETCH_CAMPAIGN_TYPES: "FETCH_CAMPAIGN_TYPES",
  SET_CAMPAIGN_TYPES: "SET_CAMPAIGN_TYPES",
  FETCH_ONEOFF_CAMPAIGNS: "FETCH_ONEOFF_CAMPAIGNS",
  SET_ONEOFF_CAMPAIGNS: "SET_ONEOFF_CAMPAIGNS",
  FETCH_ONEOFF_CAMPAIGN: "FETCH_ONEOFF_CAMPAIGN",
  SET_ONEOFF_CAMPAIGN: "SET_ONEOFF_CAMPAIGN",
  CREATE_ONEOFF_CAMPAIGN: "CREATE_ONEOFF_CAMPAIGN",
  CREATE_ONEOFF_CAMPAIGN_FULFILLED: "CREATE_ONEOFF_CAMPAIGN_FULFILLED",
  SAVE_ONEOFF_CAMPAIGN: "SAVE_ONEOFF_CAMPAIGN",
  SAVE_ONEOFF_CAMPAIGN_FULFILLED: "SAVE_ONEOFF_CAMPAIGN_FULFILLED",
  DELETE_ONEOFF_CAMPAIGN: "DELETE_ONEOFF_CAMPAIGN",
  DELETE_ONEOFF_CAMPAIGN_FULFILLED: "DELETE_ONEOFF_CAMPAIGN_FULFILLED",
  FETCH_ONGOING_CAMPAIGNS: "FETCH_ONGOING_CAMPAIGNS",
  SET_ONGOING_CAMPAIGNS: "SET_ONGOING_CAMPAIGNS",
  FETCH_ONGOING_CAMPAIGN: "FETCH_ONGOING_CAMPAIGN",
  SET_ONGOING_CAMPAIGN: "SET_ONGOING_CAMPAIGN",
  CREATE_ONGOING_CAMPAIGN: "CREATE_ONGOING_CAMPAIGN",
  CREATE_ONGOING_CAMPAIGN_FULFILLED: "CREATE_ONGOING_CAMPAIGN_FULFILLED",
  SAVE_ONGOING_CAMPAIGN: "SAVE_ONGOING_CAMPAIGN",
  SAVE_ONGOING_CAMPAIGN_FULFILLED: "SAVE_ONGOING_CAMPAIGN_FULFILLED",
  DELETE_ONGOING_CAMPAIGN: "DELETE_ONGOING_CAMPAIGN",
  DELETE_ONGOING_CAMPAIGN_FULFILLED: "DELETE_ONGOING_CAMPAIGN_FULFILLED",
  SET_DIALOGUE_NODE_SEARCH_QUERY: "SET_DIALOGUE_NODE_SEARCH_QUERY",
  FETCH_MESSAGE_PREDICTION: "FETCH_MESSAGE_PREDICTION",
  SET_MESSAGE_PREDICTION: "SET_MESSAGE_PREDICTION",
  UPDATE_EVENT: "UPDATE_EVENT",
  UPDATE_EVENT_FULFILLED: "UPDATE_EVENT_FULFILLED",
  FETCH_DECISION_TREES: "FETCH_DECISION_TREES",
  SET_DECISION_TREES: "SET_DECISION_TREES",
  FETCH_DECISION_TREES_FULFILLED: "FETCH_DECISION_TREES_FULFILLED",
  SET_DECISION_TREE: "SET_DECISION_TREE",
  FETCH_DECISION_TREE_VARIABLES: "FETCH_DECISION_TREE_VARIABLES",
  SET_DECISION_TREE_VARIABLES: "SET_DECISION_TREE_VARIABLES",
  FETCH_DECISION_TREE_VARIABLES_FULFILLED:
    "FETCH_DECISION_TREE_VARIABLES_FULFILLED",
  UPDATE_POST: "UPDATE_POST",
  UPDATE_POST_FULFILLED: "UPDATE_POST_FULFILLED",
  SETUP_AUTO_GRAPH: "SETUP_AUTO_GRAPH",
  SETUP_AUTO_GRAPH_FULFILLED: "SETUP_AUTO_GRAPH_FULFILLED",
  FETCH_TRANSLATED_CONVERSATION: "FETCH_TRANSLATED_CONVERSATION",
  SET_TRANSLATED_CONVERSATION: "SET_TRANSLATED_CONVERSATION",
  FETCH_QNA_UPLOAD: "FETCH_QNA_UPLOAD",
  FETCH_QNA_LLM_UPLOAD: "FETCH_QNA_LLM_UPLOAD",
  SET_ADMIN_RESPONSE: "SET_ADMIN_RESPONSE",
  SET_QNA_UPLOAD: "SET_QNA_UPLOAD",
  FETCH_QNA_DOWNLOAD: "FETCH_QNA_DOWNLOAD",
  FETCH_QNA_EXTRACTION: "FETCH_QNA_EXTRACTION",
  REFORMULATION_GENERATION: "REFORMULATION_GENERATION",
  SET_QNA_DOWNLOAD: "SET_QNA_DOWNLOAD",
  DELETE_QNAS: "DELETE_QNAS",
  SET_DELETE_QNAS: "SET_DELETE_QNAS",
  FETCH_UTTERANCE_UPSERT: "FETCH_UTTERANCE_UPSERT",
  SET_UTTERANCE_UPSERT: "SET_UTTERANCE_UPSERT",
  FETCH_UTTERANCE: "FETCH_UTTERANCE",
  SET_UTTERANCE: "SET_UTTERANCE",
  FETCH_ARCHIVE_CONVERSATIONS: "FETCH_ARCHIVE_CONVERSATIONS",
  SET_ARCHIVE_CONVERSATIONS: "SET_ARCHIVE_CONVERSATIONS",
  FETCH_INITIATE_CLASSIC_VERSION: "FETCH_INITIATE_CLASSIC_VERSION",
  SET_INITIATE_CLASSIC_VERSION: "SET_INITIATE_CLASSIC_VERSION",
  FETCH_DISABLE_CLASSIC_VERSION: "FETCH_DISABLE_CLASSIC_VERSION",
  SET_DISABLE_CLASSIC_VERSION: "SET_DISABLE_CLASSIC_VERSION",
  FETCH_TRIGGER_NODES_BY_SENTENCE: "FETCH_TRIGGER_NODES_BY_SENTENCE",
  SET_TRIGGER_NODES_FETCHED_BY_SENTENCE:
    "SET_TRIGGER_NODES_FETCHED_BY_SENTENCE",
  EDIT_SELECTED_TRIGGER_NODES: "EDIT_SELECTED_TRIGGER_NODES",
  DELETE_TAG_FULFILLED: "DELETE_TAG_FULFILLED",
  FETCH_TAGS_FOR_MESSAGE: "FETCH_TAGS_FOR_MESSAGE",
  SET_TAGS_FOR_MESSAGE: "SET_TAGS_FOR_MESSAGE",
  FETCH_TAGS: "FETCH_TAGS",
  SET_TAGS: "SET_TAGS",
  FETCH_TAG: "FETCH_TAG",
  SET_TAG: "SET_TAG",
  FETCH_TAG_FULFILLED: "FETCH_TAG_FULFILLED",
  UPDATE_TAG: "UPDATE_TAG",
  DELETE_TAG: "DELETE_TAG",
  PATCH_TAG: "PATCH_TAG",
  ADD_TAG_TO_MESSAGE: "ADD_TAG_TO_MESSAGE",
  ADD_TAG_TO_MESSAGE_FULFILLED: "ADD_TAG_TO_MESSAGE_FULFILLED",
  DELETE_TAG_FROM_MESSAGE: "DELETE_TAG_FROM_MESSAGE",
  DELETE_TAG_FROM_MESSAGE_FULFILLED: "DELETE_TAG_FROM_MESSAGE_FULFILLED",
  FETCH_TAGS_FOR_CONVERSATION: "FETCH_TAGS_FOR_CONVERSATION",
  SET_TAGS_FOR_CONVERSATION: "SET_TAGS_FOR_CONVERSATION",
  FETCH_TAG_CATEGORIES: "FETCH_TAG_CATEGORIES",
  SET_TAG_CATEGORIES: "SET_TAG_CATEGORIES",
  FETCH_TAG_TOPICS: "FETCH_TAG_TOPICS",
  SET_TAG_TOPICS: "SET_TAG_TOPICS",
  FETCH_TAG_REASONS: "FETCH_TAG_REASONS",
  SET_TAG_REASONS: "SET_TAG_REASONS",
  FETCH_TAG_PROBLEMS: "FETCH_TAG_PROBLEMS",
  FETCH_TAGS_BY_SENDERID: "FETCH_TAGS_BY_SENDERID",
  SET_TAG_PROBLEMS: "SET_TAG_PROBLEMS",
  SET_TAGS_BY_SENDERID: "SET_TAGS_BY_SENDERID",
  CREATE_TAG_CATEGORY: "CREATE_TAG_CATEGORY",
  CREATE_TAG_CATEGORY_FULFILLED: "CREATE_TAG_CATEGORY_FULFILLED",
  CREATE_TAG_ELEMENT: "CREATE_TAG_ELEMENT",
  CREATE_TAG_ELEMENT_FULFILLED: "CREATE_TAG_ELEMENT_FULFILLED",
  EDIT_TAG_ELEMENT: "EDIT_TAG_ELEMENT",
  EDIT_TAG_ELEMENT_FULFILLED: "EDIT_TAG_ELEMENT_FULFILLED",
  CREATE_TAG_TOPIC: "CREATE_TAG_TOPIC",
  CREATE_TAG_TOPIC_FULFILLED: "CREATE_TAG_TOPIC_FULFILLED",
  CREATE_TAG_REASON: "CREATE_TAG_REASON",
  CREATE_TAG_REASON_FULFILLED: "CREATE_TAG_REASON_FULFILLED",
  CREATE_TAG_PROBLEM: "CREATE_TAG_PROBLEM",
  CREATE_TAG_PROBLEM_FULFILLED: "CREATE_TAG_PROBLEM_FULFILLED",
  CHANGE_REVIEW_STATUS: "CHANGE_REVIEW_STATUS",
  CHANGE_REVIEW_STATUS_FULFILLED: "CHANGE_REVIEW_STATUS_FULFILLED",
  FETCH_MS_LOGIN_LINK: "FETCH_MS_LOGIN_LINK",
  SET_MS_LOGIN_LINK: "SET_MS_LOGIN_LINK",
  FETCH_MS_LOGIN_STATE: "FETCH_MS_LOGIN_STATE",
  SET_MS_LOGIN_STATE: "SET_MS_LOGIN_STATE",
  UNLINK_MS_ACCOUNT: "UNLINK_MS_ACCOUNT",
  UNLINK_MS_ACCOUNT_FULFILLED: "UNLINK_MS_ACCOUNT_FULFILLED",
  SNACKBAR_SUCCESS: "SNACKBAR_SUCCESS",
  SNACKBAR_INFO: "SNACKBAR_INFO",
  SNACKBAR_ERROR: "SNACKBAR_ERROR",
  SNACKBAR_CLEAR: "SNACKBAR_CLEAR",
  SNACKBAR_WARNING: "SNACKBAR_WARNING",
  CHANGE_PRIORITY: "CHANGE_PRIORITY",
  CHANGE_PRIORITY_FULFILLED: "CHANGE_PRIORITY_FULFILLED",
  ADD_COMMENT_TO_TAG: "ADD_COMMENT_TO_TAG",
  ADD_ADDITIONAL_ATTRIBUTES_TO_TAG: "ADD_ADDITIONAL_ATTRIBUTES_TO_TAG",
  FETCH_DOCUMENTS_INFO: "FETCH_DOCUMENTS_INFO",
  FETCH_DOCUMENTS_INFO_FULFILLED: "FETCH_DOCUMENTS_INFO_FULFILLED",
  SET_DOCUMENTS_INFO: "SET_DOCUMENTS_INFO",
  FETCH_DOCUMENTS_COUNT: "FETCH_DOCUMENTS_COUNT",
  FETCH_DOCUMENTS_COUNT_FULFILLED: "FETCH_DOCUMENTS_COUNT_FULFILLED",
  SET_DOCUMENTS_COUNT: "SET_DOCUMENTS_COUNT",
  FETCH_DOCUMENT: "FETCH_DOCUMENT",
  FETCH_DOCUMENT_FULFILLED: "FETCH_DOCUMENT_FULFILLED",
  FETCH_DOCUMENT_FAILED: " FETCH_DOCUMENT_FAILED",
  FETCH_DOCUMENTS_USAGE: "FETCH_DOCUMENTS_USAGE",
  SET_DOCUMENTS_USAGE: "SET_DOCUMENTS_USAGE",
  SET_INDEXING_DOCUMENTS: "SET_INDEXING_DOCUMENTS",
  SET_DOCUMENT: "SET_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  DELETE_DOCUMENTS: "DELETE_DOCUMENTS",
  DELETE_DOCUMENT_FULFILLED: "DELETE_DOCUMENT_FULFILLED",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
  DOWNLOAD_DOCUMENT_FULFILLED: "DOWNLOAD_DOCUMENT_FULFILLED",
  DOWNLOAD_DOCUMENT_FAILED: "DOWNLOAD_DOCUMENT_FAILED",
  SET_DOWNLOAD_DOCUMENT: "SET_DOWNLOAD_DOCUMENT",
  DOCUMENT_SEARCH: "DOCUMENT_SEARCH",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  UPDATE_DOCUMENT_FULFILLED: "UPDATE_DOCUMENT_FULFILLED",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  ADD_DOCUMENTS_TO_COLLECTION: "ADD_DOCUMENTS_TO_COLLECTION",
  ADD_DOCUMENTS_TO_COLLECTION_FULFILLED:
    "ADD_DOCUMENTS_TO_COLLECTION_FULFILLED",
  REMOVE_DOCUMENTS_FROM_COLLECTION: "REMOVE_DOCUMENTS_FROM_COLLECTION",
  REMOVE_DOCUMENTS_FROM_COLLECTION_FULFILLED: "REMOVE_DOCUMENTS_FROM_COLLECTION_FULFILLED",
  SET_DOCUMENT_VIEWPORT: "SET_DOCUMENT_VIEWPORT",
  FETCH_USER_CONVERSATIONS: "FETCH_USER_CONVERSATIONS",
  FETCH_USER_CONVERSATIONS_FULFILLED: "FETCH_USER_CONVERSATIONS_FULFILLED",
  FETCH_USER_CONVERSATIONS_FAILED: "FETCH_USER_CONVERSATIONS_FAILED",
  RESET_DOCUMENT_VIEW_FETCH_STATUS: "RESET_DOCUMENT_VIEW_FETCH_STATUS",
  RESET_PDF_DATA: "RESET_PDF_DATA",
  SET_MESSAGE_LIMIT_REACHED: "SET_MESSAGE_LIMIT_REACHED",
  SET_ANNOTATIONS: "SET_ANNOTATIONS",
  SET_ANNOTATIONS_HIGHLIGHTCOLOR: "SET_ANNOTATIONS_HIGHLIGHTCOLOR",
  SET_STORAGE_LIMIT_REACHED: "SET_STORAGE_LIMIT_REACHED",
  SET_DOCUMENT_CONVERSATION_ID: "SET_DOCUMENT_CONVERSATION_ID",
  SET_SELECTED_COPILOT_CHAT: "SET_SELECTED_COPILOT_CHAT",
  SET_NEW_CREATED_CHAT_ID: "SET_NEW_CREATED_CHAT_ID",
  SET_LOADING_NEW_DOCUMENT: "SET_LOADING_NEW_DOCUMENT",
  SET_COPILOT_AI_CONFIG: "SET_COPILOT_AI_CONFIG",
  SET_COPILOT_COLOR_MODE: "SET_COPILOT_COLOR_MODE",
  SET_DOCUMENT_INDEXING_STATUS: "SET_DOCUMENT_INDEXING_STATUS",
  SET_DOCUMENT_PAGE_LAYOUT: "SET_DOCUMENT_PAGE_LAYOUT",
  SET_COPILOT_DASHBOARD_CONFIG: "SET_COPILOT_DASHBOARD_CONFIG",
  SET_IS_COPILOT_READY: "SET_IS_COPILOT_READY",

  SET_USER_CONVERSATIONS: "SET_USER_CONVERSATIONS",
  UPSERT_USER_CONVERSATIONS: "UPSERT_USER_CONVERSATIONS",
  MANUALLY_INDEX_DOCUMENT: "MANUALLY_INDEX_DOCUMENT",
  MANUALLY_INDEX_DOCUMENTS: "MANUALLY_INDEX_DOCUMENTS",
  ARCHIVE_CONVERSATION: "ARCHIVE_CONVERSATION",
  UPSERT_COLLECTION: "UPSERT_COLLECTION",
  UPSERT_COLLECTION_FULFILLED: "UPSERT_COLLECTION_FULFILLED",
  UPSERT_COLLECTION_FAILED: "UPSERT_COLLECTION_FAILED",
  FETCH_COLLECTION: "FETCH_COLLECTION",
  SET_COLLECTION: "SET_COLLECTION",
  FETCH_COLLECTIONS: "FETCH_COLLECTIONS",
  EXPAND_COLLECTION: "EXPAND_COLLECTION",
  SET_COLLECTIONS: "SET_COLLECTIONS",
  SET_COLLECTIONS_UPSERT_STATUS: "SET_COLLECTIONS_UPSERT_STATUS",
  DELETE_COLLECTION: "DELETE_COLLECTION",
  DELETE_COLLECTION_FULFILLED: "DELETE_COLLECTION_FULFILLED",
  SET_IS_TRASH_COLLECTION: "SET_IS_TRASH_COLLECTION",
  SET_PDF_DATA: "SET_PDF_DATA",
  SET_CONVERSATION_EVENTS: "SET_CONVERSATION_EVENTS",
  SET_CONVERSATION_EVENTS_FULFILLED: "SET_CONVERSATION_EVENTS_FULFILLED",

  FETCH_GRAPHS_DOWNLOAD: "FETCH_GRAPHS_DOWNLOAD",
  SET_GRAPHS_DOWNLOAD: "SET_GRAPHS_DOWNLOAD",

  FETCH_GRAPHS_UPLOAD: "FETCH_GRAPHS_UPLOAD",
  SET_GRAPHS_UPLOAD: "SET_GRAPHS_UPLOAD",

  FETCH_ACCESS_LEVELS: "FETCH_ACCESS_LEVELS",
  SET_ACCESS_LEVELS: "SET_ACCESS_LEVELS",
  CREATE_ACCESS_LEVEL: "CREATE_ACCESS_LEVEL",
  CREATE_ACCESS_LEVEL_FULFILLED: "CREATE_ACCESS_LEVEL_FULFILLED",
  DELETE_ACCESS_LEVEL: "DELETE_ACCESS_LEVEL",
  DELETE_ACCESS_LEVEL_FULFILLED: "DELETE_ACCESS_LEVEL_FULFILLED",
  FETCH_ROOT_COLLECTIONS: "FETCH_ROOT_COLLECTIONS",
  SET_ROOT_COLLECTIONS: "SET_ROOT_COLLECTIONS",
  UPDATE_ACCESS_LEVEL: "UPDATE_ACCESS_LEVEL",
  UPDATE_ACCESS_LEVEL_FULFILLED: "UPDATE_ACCESS_LEVEL_FULFILLED",
  FETCH_DIALOGUE_STATISTICS: "FETCH_DIALOGUE_STATISTICS",
  FETCH_DIALOGUE_STATISTICS_FULFILLED: "FETCH_DIALOGUE_STATISTICS_FULFILLED",

  SET_DEMO_DRAWER_STATE: "SET_DEMO_DRAWER_STATE",

  SET_DIALOGUE_GRAPH_PUBLISHED_AT: "SET_DIALOGUE_GRAPH_PUBLISHED_AT",
  SET_DIALOGUE_GRAPH_DATA_PUBLISHED_STATUS:
    "SET_DIALOGUE_GRAPH_DATA_PUBLISHED_STATUS",
};
