import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchDialogueGraph = (data) => ({
  type: Actions.FETCH_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
});

export const createDialogueGraph = (channel, name = "") => ({
  type: Actions.CREATE_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
  channel: channel,
  name: name,
});

export const createFormDialogueGraph = (
  channel,
  form_id,
  dialogue_graph_id
) => ({
  type: Actions.CREATE_FORM_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
  channel: channel,
  form_id: form_id,
  dialogue_graph_id: dialogue_graph_id,
});

export const copyDialogueGraph = (sourceId) => ({
  type: Actions.COPY_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
  sourceId: sourceId,
});

export const copyDialogueGraphTemplate = (sourceId, data = null) => ({
  type: Actions.COPY_DIALOGUE_GRAPH_TEMPLATE,
  token: getKeycloakToken(),
  sourceId: sourceId,
  data: data,
});

export const deleteDialogueGraphs = (graphIds) => ({
  type: Actions.DELETE_DIALOGUE_GRAPHS,
  token: getKeycloakToken(),
  graphIds: graphIds,
});

export const deleteDialogueGraphsFulfilled = (statusMessage) => ({
  type: Actions.DELETE_DIALOGUE_GRAPHS_FULFILLED,
  status: statusMessage,
});

export const fetchDialogueGraphs = (channel) => ({
  type: Actions.FETCH_DIALOGUE_GRAPHS,
  token: getKeycloakToken(),
  channel: channel,
});

export const fetchDialogueUrls = (channel) => ({
  type: Actions.FETCH_DIALOGUE_URLS,
  token: getKeycloakToken(),
  channel: channel,
});

export const fetchDialogueGraphBlueprints = (channel) => ({
  type: Actions.FETCH_DIALOGUE_GRAPH_BLUEPRINTS,
  token: getKeycloakToken(),
  channel: channel,
});

export const setDialogueGraphData = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPH,
  payload: data,
});

export const setDialogueGraphInfo = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPH_INFO,
  payload: data,
});

export const setDialogueGraphChannel = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPH_CHANNEL,
  payload: data,
});

export const saveDialogueGraphData = (data) => ({
  type: Actions.SAVE_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
});

export const saveDialogueGraphInfo = (graphId, payload) => ({
  type: Actions.SAVE_DIALOGUE_GRAPH_INFO,
  token: getKeycloakToken(),
  graphId: graphId,
  payload: payload,
});

export const setGraphId = (data) => ({
  type: Actions.SET_GRAPH_ID,
  graphId: data,
  token: getKeycloakToken(),
});

export const clearGraphHistory = () => ({
  type: Actions.CLEAR_GRAPH_HISTORY,
});

export const saveDialogueGraphDataFulfilled = (statusMessage) => ({
  type: Actions.SAVE_DIALOGUE_GRAPH_FULFILLED,
  status: statusMessage,
  timestamp: new Date().getTime(),
});

export const saveDialogueGraphInfoFulfilled = (statusMessage) => ({
  type: Actions.SAVE_DIALOGUE_GRAPH_INFO_FULFILLED,
  status: statusMessage,
});

export const saveDialogueGraphError = (statusMessage) => ({
  type: Actions.SAVE_DIALOGUE_GRAPH_ERROR,
  status: statusMessage,
});

export const publishDialogueGraphData = () => ({
  type: Actions.PUBLISH_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
});

export const publishDialogueGraphDataFulfilled = (statusMessage) => ({
  type: Actions.PUBLISH_DIALOGUE_GRAPH_FULFILLED,
  status: statusMessage,
  timestamp: new Date().getTime(),
});

export const publishDialogueGraphError = (statusMessage) => ({
  type: Actions.PUBLISH_DIALOGUE_GRAPH_ERROR,
  status: statusMessage,
});

export const trainDialogueGraphData = () => ({
  type: Actions.TRAIN_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
});

export const trainDialogueGraphDataFulfilled = (statusMessage) => ({
  type: Actions.TRAIN_DIALOGUE_GRAPH_FULFILLED,
  status: statusMessage,
  timestamp: new Date().getTime(),
});

export const revertDialogueGraphData = () => ({
  type: Actions.REVERT_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
});

export const revertDialogueGraphDataFulfilled = (statusMessage) => ({
  type: Actions.REVERT_DIALOGUE_GRAPH_FULFILLED,
  status: statusMessage,
});

export const fetchAllDialogueGraph = () => ({
  type: Actions.FETCH_ALL_DIALOGUE_GRAPH,
  token: getKeycloakToken(),
});

export const setDialogueGraphs = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPHS,
  payload: data,
});

export const setDialogueUrls = (data) => ({
  type: Actions.SET_DIALOGUE_URLS,
  payload: data,
});

export const setDialogueGraphBlueprints = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPH_BLUEPRINTS,
  payload: data,
});

export const addDialogueElement = (data) => ({
  type: Actions.ADD_DIALOGUE_ELEMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const addDialogueElementFulfilled = (data) => ({
  type: Actions.ADD_DIALOGUE_ELEMENT_FULFILLED,
});

export const addDialogueElementRejected = (data) => ({
  type: Actions.ADD_DIALOGUE_ELEMENT_REJECTED,
});

export const deleteDialogueElement = (data) => ({
  type: Actions.DELETE_DIALOGUE_ELEMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteDialogueElementFulfilled = (data) => ({
  type: Actions.DELETE_DIALOGUE_ELEMENT_FULFILLED,
});

export const deleteDialogueElementRejected = (data) => ({
  type: Actions.DELETE_DIALOGUE_ELEMENT_REJECTED,
});

export const clearDialogueGraph = () => ({
  type: Actions.CLEAR_DIALOGUE_GRAPH,
});

export const clearDialogueGraphs = () => ({
  type: Actions.CLEAR_DIALOGUE_GRAPHS,
});

export const clearAllDialogueGraph = () => ({
  type: Actions.CLEAR_ALL_DIALOGUE_GRAPH,
});

export const fetchActionNodeTemplate = (type, variant) => ({
  type: Actions.FETCH_ACTION_NODE_TEMPLATE,
  nodeType: type,
  nodeVariant: variant,
  token: getKeycloakToken(),
});

export const setActionNodeTemplate = (data) => ({
  type: Actions.SET_ACTION_NODE_TEMPLATE,
  payload: data,
  token: getKeycloakToken(),
});

export const fetchDialogueGraphVariables = () => ({
  type: Actions.FETCH_DIALOGUE_GRAPH_VARIABLES,
  token: getKeycloakToken(),
});

export const setDialogueGraphVariables = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPH_VARIABLES,
  payload: data,
});

export const setDialogueGraphSavedStatus = (data) => ({
  type: Actions.SET_DIALOGUE_GRAPH_SAVED_STATUS,
  payload: data,
});

export const fetchChannelLimitations = (channel) => ({
  type: Actions.FETCH_CHANNEL_LIMITATIONS,
  channel: channel,
  token: getKeycloakToken(),
});

export const fetchChannelShareLinks = (channel) => ({
  type: Actions.FETCH_CHANNEL_SHARE_LINKS,
  channel: channel,
  token: getKeycloakToken(),
});

export const fetchChannelLimitationsFulfilled = () => ({
  type: Actions.FETCH_CHANNEL_LIMITATIONS_FULFILLED,
});

export const setChannelLimitations = (data) => ({
  type: Actions.SET_CHANNEL_LIMITATIONS,
  payload: data,
});

export const setChannelShareLinks = (data) => ({
  type: Actions.SET_CHANNEL_SHARE_LINKS,
  payload: data,
});

export const fetchProactiveMessage = () => ({
  type: Actions.FETCH_PROACTIVE_MESSAGE,
  token: getKeycloakToken(),
});

export const setProactiveMessage = (data) => ({
  type: Actions.SET_PROACTIVE_MESSAGE,
  payload: data,
});

export const saveProactiveMessage = (data) => ({
  type: Actions.SAVE_PROACTIVE_MESSAGE,
  payload: data,
  token: getKeycloakToken(),
});

export const saveProactiveMessageFulfilled = () => ({
  type: Actions.SAVE_PROACTIVE_MESSAGE_FULFILLED,
});

export const fetchEedNotificationData = () => ({
  type: Actions.FETCH_EED_NOTIFICATION_DATA,
  token: getKeycloakToken(),
});

export const setEedNotificationData = (data) => ({
  type: Actions.SET_EED_NOTIFICATION_DATA,
  payload: data,
});

export const saveEedNotificationData = (data) => ({
  type: Actions.SAVE_EED_NOTIFICATION_DATA,
  payload: data,
  token: getKeycloakToken(),
});

export const saveEedNotificationDataFulfilled = () => ({
  type: Actions.SAVE_EED_NOTIFICATION_DATA_FULFILLED,
});

export const setDialogueNodeSearchQuery = (data) => ({
  type: Actions.SET_DIALOGUE_NODE_SEARCH_QUERY,
  payload: data,
});

export const setupAutoDialogueGraph = (payload) => ({
  type: Actions.SETUP_AUTO_GRAPH,
  token: getKeycloakToken(),
  payload: payload,
});

export const setDemoDrawerState = (isOpen) => ({
  type: Actions.SET_DEMO_DRAWER_STATE,
  payload: isOpen,
});

export const setDialogueGraphPublishedAt = (timestamp) => ({
  type: Actions.SET_DIALOGUE_GRAPH_PUBLISHED_AT,
  payload: timestamp,
});

export const setDialogueGraphDataPublishedStatus = (status) => ({
  type: Actions.SET_DIALOGUE_GRAPH_DATA_PUBLISHED_STATUS,
  payload: status,
});
